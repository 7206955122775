@tailwind base;
@tailwind components;
@tailwind utilities;

.pricing-starter-txt-includes {
    @apply text-gray-800 text-base font-normal;
}

.pricing-starter-txt-notincludes {
    @apply text-gray-400 text-base font-normal;
}

.pricing-premium-txt {
    @apply text-white text-base font-normal;
}